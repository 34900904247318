.editUser{
    display : flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 12px;
}
.editUser div{
    width : 100%;
}
.editInput{
    margin : 5px 0;
}
button{
    color : white;
    background-color: var(--buttonHighlightedColor);
    border : none;
    padding : 7px 13px;
    font-size: 16px;
    border: 2px solid #ff5e5e;
    border-radius: 6px;
    margin-top: 5px;
}
.ant-btn-primary{
    color : white;
    background-color: var(--buttonHighlightedColor);

}

.ant-btn-primary:hover {
    color: white !important;
    background-color: var(--buttonBackgroundColor) !important;

}