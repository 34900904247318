

:root {
    --navBackgroundColor : #EAF5FF;
    --buttonBackgroundColor : #447EBF;
    --buttonHighlightedColor : #ff5e5e;
    --bodyBackground : #f5f5f5;
    --sidebarBackground : white;
    --navContact : #e8ffff;
    --editButton : #def5e6;
    --deleteButton : #ffdede;
    --editText : #3ac977;
    --deleteText : #ff7e7e;
    

}