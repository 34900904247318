.container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    color: #447EBF;
}

.innerContainer {

    height: 550px;
    padding: 0 8%;
    border-radius: 29px;
    border: 1px;
    background-color: #EAF5FF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

.changePassword {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.changePassword h3 {
    text-align: center;
    font-size: 30px;


}

.changePassword p {
    text-align: center;
    font-size: 16px;

}

.button2 {
    background-color: #FF5E5E;
    color: white;
    width: 100%;
    height: 44px;
    border: none;
    border: 2px solid #ff5e5e;
    border-radius: 6px;
    font-weight: bold;
    font-size: 20px;
    margin-top: 20px;
}

input {
    height: 40px;
    width: 99%;
    border-radius: 6px;
    border: 2px solid #cccccc !important;
    padding: 0 0px;
    font-size: 16px;
    outline: none;


}

input:focus {
    border: 2px solid #cccccc !important;
}

.changePasswordInput2 {
 margin: 20px 0;
margin-top: 90px;
}

.innerBox {

    width: 100%;

}

.changePasswordInput p {
    font-weight: bold;
}

.logo {
    display: flex;
    justify-content: center;
    align-items: center;


}

.logo img {
    width: 200px;
    margin-bottom: 20px;


}

.checkCircle {
    display: flex;
    justify-content: center;
    align-items: center;
    color: green !important;
    font-size: 80px;
    margin: 20px 0;
}

.container2 {
    height: 300px;
    color: #447EBF;
}