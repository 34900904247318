@import url('../../Url/colors.css');

.Navbar {
    background-color: var(--navBackgroundColor);
  display : flex;
  justify-content: space-between;
  align-items: center;
  padding : 10px 50px;
  height: 43px;
}
.NavbarLeft {
    display: flex;
    justify-content: center;
    align-items: center;
}

.NavbarLeft img {
    width : 170px;
}
@media screen and (max-width: 568px) {
    .NavbarLeft img {
        max-width: 80px;
        position : absolute;
        left : 10px;
        
    }
}
.NavbarRight {
    display : flex;
    justify-content: center;
    align-items: center;
color : var(--buttonBackgroundColor);

}
.NavbarLeft div{
    margin-left : 20px;
    display: flex;
    justify-content: center; 
    align-items: center;
background-color: var(--buttonBackgroundColor);
}
.NavbarLeft input{
   border : none;
   outline : none;
   height : 30px;
  padding: 5px 2px;
  width: 170px;
background-color: var(--buttonBackgroundColor);
border-radius: 2px;
}
.NavbarLeft div{
   padding : 0 10px;
}

.NavbarLeft  input:focus {
    outline: none!important;
}


.menuIcon {
    background-color: var(--buttonBackgroundColor);
    height: 20px;
    padding : 5px ;
    color : white;
    margin-left: 20px;
    font-weight: bold;
    height: 30px;
    border-radius : 2px;
        padding: 5px 12px;
    font-size: 18px;
}
.menuIcon2 {
    color : white;
}
input::placeholder {
    color: white;
    
}





.NavbarRight div{
    margin-left: 12px;
    line-height: 20px;
}
.switch{
    display : flex;
    align-items: center;
    font-size: 32px;
    justify-content: center;
}
.Navbar input{
    border : none!important;
}