.contactDetailVideo{
    display: flex;
    justify-content: space-evenly;
        font-weight: 400;
        padding: 10px;
        border-bottom: 2px solid var(--bodyBackground);
}

.contactItemHeight {
    height : 38px;
    align-items: center;
    display : flex;
    justify-content: center;

  
}
.contactNavbar{
  display : flex;
  justify-content: left;
  width : 100%;
}
.contactItemButton{
    
    flex-direction: column;
    justify-content: center;
   
    display : flex;
}
.contactItem1{
   
    width : 100%;
    align-items: left;
}
.contactItemSub{
    align-items: left;
    display : flex;
 
    justify-content: left;
}
.buttonCenter{
    align-items: center;
    justify-content: center;
}
.downButton{
    width : 130px;
    justify-content: center;
}
.buttonz{
    display : flex;
    justify-content: center!important;
    align-items: center;

}
/* .ant-btn-default:hover{
   border : 1px solid var(--bodyBackground);
   color : var(--bodyBackground);
   display : flex;
   justify-content: center;
   align-items: center;
   border-radius: 2px ;

} */
.contactNavSmall{
 
display : grid;
padding: 10px;
grid-template-columns:  0.5fr 2fr 2fr 4fr;
border-bottom: 2px solid var(--bodyBackground);
}
.contactNavSmallBar{
    display: grid;
        grid-template-columns: 0.5fr 2fr 2fr 4fr;
      
        background-color: var(--navContact);
        font-weight: 500;
        padding: 10px;
       
}