@import url('../../Url/colors.css');
.ContactDetails{
    background-color:  var(--sidebarBackground);
    width: 100%;
    height : 81vh;
    overflow : hidden;
    position : relative;
    display : flex;
   flex-direction: column;


}
.contactNavbar{
    display : flex;
    justify-content: space-evenly;
    align-items: center;
    background-color: var(--navContact);
    font-weight: 500;
    padding : 10px;
    height  : 40px;
}
.contactItem{
    text-align: left;
     width : 100%;
     display : flex;

}
.contactDetail{
    display: flex;
        justify-content: space-evenly;
        align-items: center;
     
        font-weight: 400;
        padding: 10px;
        border-bottom: 2px solid var(--bodyBackground);
}

.editButton , .deleteButton {
    border-radius: 2px;
    

}
.editButton{
    background-color: var(--editButton);
    color  : var(--editText);
    border : none;
    padding : 5px 20px;
    
    margin-right: 10px;
    font-size: 15px;
}
.deleteButton{
    background-color: var(--deleteButton);
    color  : var(--deleteText);
    border : none;
    padding : 5px 20px;
    font-size: 15px;
    width : 100px;
}
.buttons{
    display : flex;
    justify-content : center;
}
.selectOptionPending{
    background-color:  var(--editButton);
    color : var(--editText);
    border : none;
    border-radius: 2px;
    padding :2px 10px ;
    height : 34px;
}
.selectOptionActive{
    background-color:  var(--deleteButton);
    color : var(--deleteText);
    border : none;
    border-radius: 2px;
    padding :2px 10px ;
    height: 34px;
}

.adminPagination{
    display : flex;
    font-weight: 200;
    justify-content: space-between;
    font-size: 10px;
    /* position : absolute; */
    /* background-color: white; */
    bottom : 0;
    align-items: center;
    padding-bottom: 5px;
    height  :50px;
    width : 100%;
}
.paginationButton {
    background: none;
    border : none;
    
    padding : 3px 10px;
}
.activePagination {
    background: red;
    border : none;
    padding : 3px 10px;
    color : white;
}
.paginationDetail 
{

    padding-left: 20px;
}
.paginationButtons,.ant-pagination
{
  justify-content: center;
  align-items: center;
    font-size: 10px;
display : flex;
    /* padding-right: 40px; */
}

.ant-pagination .ant-pagination-options{
    display :none;}

.ant-pagination-item-active{
        background: red!important;
            border: none!important;
            padding: 2px 10px!important;
            color: white!important;

}
.ant-pagination-item-active a{
            color: white!important;

}
.headingNav{
    display : flex;
    justify-content: space-between;
    align-items: center;
    padding : 10px 18px;
    height : 60px;
    
}
.headingNav  button {
    margin :0;

}
.desktopOnly {
    @media (max-width: 1008px) {
        display: none;
    }
}
.contactItem{
    
}
.buttonsEdit{
    display : flex;
    justify-content: center;
    align-items: center;
    padding : 0 20px;
}
/* ContactDetails.css */

.smallScreenBorder {
    border: 1px solid #ccc;
    /* Adjust border style as needed */
}
.contactItemDetails ,.buttonSmallScreen{

 width : 100%;
 display : flex;
 justify-content: center;
 align-items: center;
 padding-bottom: 5px;

}

.ant-pagination-next,.ant-pagination-prev {
display :flex;
justify-content: center;
    align-items: center;
    margin-bottom: 12px;
}
.contactsList{
    flex : 1;
}
.contactPage{
    position : relative;
    display : flex;
    flex-direction: column;
    justify-content: space-between;
    flex : 1;
    position : relative;
}