.searchBar{
    position : fixed;
    top  : 11px;
    display : flex;
    left: 300px;
    border-radius: 2px;
    padding :  6px;
}
.searchBar2{
    position : fixed;
    top  : 11px;
    display : flex;
    right: 10px;
    border-radius: 2px;
    padding :  6px;
    
}
.searchBarInput {
    border: none;
        outline: none;
      height : 100%;
        padding: 5px 2px;
        width: 170px;
        background-color: var(--buttonBackgroundColor);
        border-radius: 2px;
        font-size: 12px;
}
 .searchBarInput:focus {
     outline: none !important;
     border : none!important;
     color : white;
 }
 .searchBarInput{
     border: none !important;
 }
 .searchBar  {
   
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: var(--buttonBackgroundColor);
 }
 .searchBar2  {
   
     display: flex;
     justify-content: center;
     align-items: center;
     background-color: var(--buttonBackgroundColor);
 }
 .menuIcon2 {
     color: white;
     font-size:25px ;
    padding : 2px;
    
 }
