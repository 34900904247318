@import url('../../Url/colors.css');

.sideBar {
    width : 250px;
    height : 100vh;
    background-color: var(--sidebarBackground);
}
.menu {
    font-size: 14px;
    display :flex;
    align-items: center;
    /* padding : 10px 0; */
    
}

.icon {
    /* padding : 0 15px; */
    margin-right : 5px;
}
.activeMenu {
    background-color: var(--buttonHighlightedColor);
    color: white;
    /* border : 2px solid green!important; */
}
.ant-menu-item-selected {
     /* border : 2px solid green!important; */
background-color: var(--buttonHighlightedColor)!important;
        color: white!important;
        display : flex;
        justify-content: center;
        align-items: center;
       

}
.toggleButton {
    background-color: var(--buttonBackgroundColor);
    padding: 5px;
    color: white;
    font-weight: bold;
    border-radius: 2px;
    padding: 5px 12px;
    font-size: 18px;
    left : 250px;
    position :fixed;
    height : 40px;
    top : 11px;
    width : 45px;
   
}
.toggleButton2 {
    background-color: var(--buttonBackgroundColor);
    padding: 5px;
    color: white;
    font-weight: bold;
    border-radius: 2px;
    padding: 5px 12px;
    font-size: 18px;
    right : 220px;
    position :fixed;
    height : 40px;
    top : 11px;
    width : 45px;
   
}

.siderNav{
    position : absolute!important;
    left : 0;
    z-index : 222;
    height: 90vh;

}
.ant-menu-title-content{
    padding-bottom: 10px;
    /* border : 2px solid green!important; */
}
.userDetails{
    position : absolute;
    bottom : 30px;
}